#home {
    background-image: url(./img/bg_image_1.jpg);
}

#profile-pic {
    content: url(./img/person.jpg);
}

.js-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/js_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.node-js-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/node_js_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.react-js-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/react_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.python-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/python_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.git-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/git_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.bootstrap-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/bootstrap_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.mongo-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/mongo_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.redux-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/redux_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.typescript-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/typescript_logo.jpeg);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.redux-saga-logo-container {
    width: 120px;
    height: 120px;
    background-image: url(./img/redux_saga_logo.png);
    background-size: contain;
    border-radius: 50%;
    border: 3px solid grey;
    background-repeat: no-repeat;
    margin: auto;
}

.logo-container {
    padding: 10px;
}

.logo-title {
    text-align: center;
    font-weight: 800;
    padding: 5px;
}

#tricolor-project-image {
    content: url(./img/tricolor.png);
}

#quick-sort-project-image {
    content: url(./img/quick_sort.png);
}
#merge-sort-project-image{
    content: url(./img/merge_sort.png);
}

#titanic-project-image {
    content: url(./img/titanic.jpeg);
}
#elephant-project-image {
    content: url(./img/elephant.png);
}

.card-width {
    width: 18rem;
}

.margin-bottom-40 {
    margin: 40px;
}

main#carousel {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    width: 100vw;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 600px;
    --items: 5;
    --middle: 3;
    --position: 1;
}

div.item {
    position: absolute;
    width: 300px;
    height: 400px;
    background-color: white;
    --r: calc(var(--position) - var(--offset));
    --abs: max(calc(var(--r) * -1), var(--r));
    transition: all 0.25s linear;
    transform: rotateY(calc(-10deg * var(--r))) translateX(calc(-300px * var(--r)));
    z-index: calc((var(--position) - var(--abs)));
}

div.item:nth-of-type(1) {
    --offset: 1;
}

div.item:nth-of-type(2) {
    --offset: 2;
}

div.item:nth-of-type(3) {
    --offset: 3;
}

div.item:nth-of-type(4) {
    --offset: 4;
}

div.item:nth-of-type(5) {
    --offset: 5;
}

input:nth-of-type(1) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

input:nth-of-type(1):checked~main#carousel {
    --position: 1;
}

input:nth-of-type(2) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

input:nth-of-type(2):checked~main#carousel {
    --position: 2;
}

input:nth-of-type(3) {
    grid-column: 4 /5;
    grid-row: 2 / 3;
}

input:nth-of-type(3):checked~main#carousel {
    --position: 3;
}

input:nth-of-type(4) {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
}

input:nth-of-type(4):checked~main#carousel {
    --position: 4;
}

input:nth-of-type(5) {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
}

input:nth-of-type(5):checked~main#carousel {
    --position: 5;
}

.card-slider-container {
    height: 600px;
    margin: 0;
    display: grid;
    grid-template-rows: 500px 100px;
    grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
    align-items: center;
    justify-items: center;
}

.card-shadow {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
}
.set-card-height{
    height: 400px !important;
}
.set-card-img-h{
    height: 200px;
}
.underconstriction{
    position: absolute;
    top: 80px;
    left: 0px;
    color: white;
}
#carousel{
    width: 100% !important;
}
.product-card {
    width: 220px;
    height: 300px;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin: 60px 0px;
    cursor: pointer;
    padding: 15px;
}
.product-card:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transition: 0.3s;
}
.edukite-logo{
    content: url(./img/edukite-logo.png);
}
.product-card-logo-container{
    padding: 20px 0px;
}
.product-title{
    font-weight: 700;
    font-size: 20px;
    padding: 10px 0px;
    text-align: center;
}
.product-description{
    text-align: center;
    padding: 10px 0px;
}
.freshflows-logo{
    content: url(./img/freshflows-logo.svg);
}
.auzmor-hire-logo{
    content: url(./img/auzmor-hire-logo.svg);
}
.auzmor-office-logo{
    content: url(./img/auzmor-office-logo.svg);
}
.text-decoration-none{
    text-decoration: none;
    text-decoration-color: #000;
    color: #000;
}